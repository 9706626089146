'use client'

import { createStitches } from '@stitches/react'
import type { PropertyValue } from '@stitches/react'
export type { CSS, VariantProps } from '@stitches/react'

export const mediaBreakPoints = {
  bp1: '(max-width: 640px)',
  mbp1: '(min-width: 640px)',
  bph: '(max-width: 800px)',
  bp2: '(max-width: 900px)',
  bp3: '(max-width: 1090px)',
}

export const {
  config,
  createTheme,
  css,
  getCssText,
  globalCss,
  styled,
  theme,
  keyframes,
} = createStitches({
  theme: {
    colors: {
      white: 'rgba(255, 255, 255, 1)',
      whiteA800: 'rgba(255, 255, 255, 0.8)',
      black: 'rgba(0, 0, 0, 1)',

      yellow30: 'rgba(218, 141, 25, 0.3)',
      yellow100: '#DA8D19',

      blue05: 'rgba(0, 134, 205, 0.05)',
      blue10: 'rgba(0, 134, 205, 0.1)',
      blue100: 'rgba(0, 134, 205, 1)',
      blue200: 'rgba(0, 116, 177, 1)',

      green05: 'rgba(16, 164, 137, 0.05)',
      green10: 'rgba(16, 164, 137, 0.1)',
      green30: 'rgba(16, 164, 137, 0.3)',
      green100: 'rgba(16, 164, 137, 1)',
      green200: 'rgba(13, 133, 111, 1)',

      red10: 'rgba(207, 0, 92, 0.1)',
      red100: 'rgba(207, 0, 92, 1)',
      red200: 'rgba(179, 1, 79, 1)',

      purple01: 'rgba(83, 104, 229, 1)',
      purple05: 'rgba(75, 47, 210, 0.05)',
      purple10: 'rgba(75, 47, 210, 0.1)',
      purple100: 'rgba(75, 47, 210, 1)',
      purple200: 'rgba(62, 41, 168, 1)',

      grey5: '#F7F7F7',
      grey10: '#F2F1F7',
      grey100: 'rgba(202, 203, 204, 1)',
      grey150: 'rgba(115, 121, 128, 1)',
      grey200: 'rgba(102, 114, 128, 1)',
      grey300: 'rgba(61, 73, 87, 1)',
      grey400: 'rgba(27, 32, 38, 1)',

      adminGrey50: 'rgba(248, 248, 248, .5)',
      adminGrey100: '#F8F8F8',
      adminGrey200: '#F1F1F1',
      adminGrey250: '#FBFBFB',
      adminGrey300: '#E6E6E6',
      adminGrey400: '#B1B1B1',
      adminGrey500: '#858585',
    },
    space: { medium: '16px', large: '24px' },
    sizes: {
      container: '1200px',
      32: '32px',
      40: '40px',
      42: '42px',
      48: '48px',
    },
    fonts: {
      inter: 'Inter, -apple-system, system-ui, sans-serif',
      roboto: 'Roboto Mono',
    },
    fontWeights: {
      book: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    fontSizes: {
      button: '16px',
      body: '16px',
      bodyMedium: '18px',
      bodyLarge: '20px',
      bodyExtraSmall: '14px',
      bodySmall: '15px',
      headline1: '48px',
      headline2: '40px',
      headline3: '32px',
      headline4: '24px',
    },
    lineHeights: {
      1: 1.15,
      2: 1.3,
      3: 1.55,
    },
    letterSpacings: {},
    borderWidths: {},
    borderStyles: {},
    radii: {
      1: '6px',
      2: '8px',
      16: '16px',
    },
    shadows: {
      1: '0px 4px 25px rgba(0, 0, 0, 0.15)',
      purple100: 'hsl(245,76%,57%)',
      purple200: 'hsla(245,76%,57%, 5%)',
    },
    zIndices: {},
    transitions: {},
  },
  utils: {
    square: (value: PropertyValue<'width' | 'height'>) => ({
      width: value,
      height: value,
      minHeight: value,
      minWidth: value,
      maxHeight: value,
      maxWidth: value,
    }),
    spacingY: (value: PropertyValue<'marginBlock'>) => ({
      '& > :not(style) ~ :not(style)': {
        marginBlock: `${value} 0`,
      },
    }),
    spacingX: (value: PropertyValue<'marginInline'>) => ({
      '& > :not(style) ~ :not(style)': {
        marginInline: `${value} 0`,
      },
    }),
    mx: (value: PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: PropertyValue<'marginTop'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    px: (value: PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
  media: mediaBreakPoints,
})

export const ease = [0.165, 0.84, 0.44, 1]

export const VariantsColorsRelation = [
  { color: 'Standard', value: '$blue100' },
  { color: 'Simulacrum', value: '$green100' },
  { color: 'Study', value: '$purple100' },
  { color: 'Psychotechnical', value: 'rgba(238, 69, 98, 1)' },
  { color: 'CaseStudy', value: 'rgba(249, 131, 22, 1)' },
  { color: 'black', value: '$black' },
  { color: 'grey', value: '$grey100' },
]

export const VariantsColorsHoverCircle = [
  { color: 'Standard', value: 'rgba(0, 148, 215, 0.1)' },
  { color: 'Simulacrum', value: 'rgba(16, 164, 137, 0.1)' },
  { color: 'Study', value: 'rgba(104, 78, 233, 0.1)' },
  { color: 'Psychotechnical', value: 'rgba(238, 69, 98, 0.1)' },
  { color: 'CaseStudy', value: 'rgba(249, 131, 22, 0.1)' },
  { color: 'black', value: '$black' },
  { color: 'grey', value: '$grey100' },
]

export const VariantsColorsOpacityRelation = [
  { color: 'Standard', value: '$blue10' },
  { color: 'Simulacrum', value: '$green10' },
  { color: 'Study', value: '$purple10' },
  { color: 'Psychotechnical', value: 'rgba(238, 69, 98, 0.1)' },
  { color: 'CaseStudy', value: 'rgba(249, 131, 22, 0.1)' },
  { color: 'black', value: '$black' },
  { color: 'grey', value: '$grey10' },
]
