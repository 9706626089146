'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    // <svg
    //   width="10"
    //   height="14"
    //   viewBox="0 0 10 14"
    //   fill="#737980"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}>
    //   <path
    //     d="M8.0625 5.25H7.625V3.0625C7.625 2.36631 7.34844 1.69863 6.85616 1.20634C6.36387 0.714062 5.69619 0.4375 5 0.4375C4.30381 0.4375 3.63613 0.714062 3.14384 1.20634C2.65156 1.69863 2.375 2.36631 2.375 3.0625V5.25H1.9375C1.47353 5.25051 1.0287 5.43504 0.700623 5.76312C0.372544 6.0912 0.188007 6.53603 0.1875 7V11.8125C0.188007 12.2765 0.372544 12.7213 0.700623 13.0494C1.0287 13.3775 1.47353 13.562 1.9375 13.5625H8.0625C8.52647 13.562 8.9713 13.3775 9.29938 13.0494C9.62746 12.7213 9.81199 12.2765 9.8125 11.8125V7C9.81199 6.53603 9.62746 6.0912 9.29938 5.76312C8.9713 5.43504 8.52647 5.25051 8.0625 5.25ZM6.75 5.25H3.25V3.0625C3.25 2.59837 3.43437 2.15325 3.76256 1.82506C4.09075 1.49687 4.53587 1.3125 5 1.3125C5.46413 1.3125 5.90925 1.49687 6.23744 1.82506C6.56563 2.15325 6.75 2.59837 6.75 3.0625V5.25Z"
    //     fill="#parent"
    //   />
    // </svg>
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="lock-closed">
        <path
          id="Vector"
          d="M7.90625 3.75H7.5625V2.1875C7.5625 1.69022 7.3452 1.21331 6.95841 0.861675C6.57161 0.510044 6.04701 0.3125 5.5 0.3125C4.95299 0.3125 4.42839 0.510044 4.04159 0.861675C3.6548 1.21331 3.4375 1.69022 3.4375 2.1875V3.75H3.09375C2.7292 3.75036 2.37969 3.88217 2.12192 4.11652C1.86414 4.35086 1.71915 4.66859 1.71875 5V8.4375C1.71915 8.76891 1.86414 9.08664 2.12192 9.32098C2.37969 9.55533 2.7292 9.68714 3.09375 9.6875H7.90625C8.2708 9.68714 8.62031 9.55533 8.87808 9.32098C9.13586 9.08664 9.28085 8.76891 9.28125 8.4375V5C9.28085 4.66859 9.13586 4.35086 8.87808 4.11652C8.62031 3.88217 8.2708 3.75036 7.90625 3.75ZM6.875 3.75H4.125V2.1875C4.125 1.85598 4.26987 1.53804 4.52773 1.30362C4.78559 1.0692 5.13533 0.9375 5.5 0.9375C5.86467 0.9375 6.21441 1.0692 6.47227 1.30362C6.73013 1.53804 6.875 1.85598 6.875 2.1875V3.75Z"
          fill="#parent"
        />
      </g>
    </svg>
  )
}

export const Lock = styled(Svg)
