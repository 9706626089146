'use client'
export function TickDoneCircle(props) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        id="Vector"
        d="M9.41838 4.54458C9.48571 4.61168 9.53913 4.69141 9.57559 4.77919C9.61204 4.86698 9.6308 4.9611 9.6308 5.05616C9.6308 5.15121 9.61204 5.24533 9.57559 5.33312C9.53913 5.42091 9.48571 5.50063 9.41838 5.56773L6.04801 8.9381C5.98091 9.00543 5.90119 9.05885 5.8134 9.09531C5.72561 9.13176 5.63149 9.15052 5.53644 9.15052C5.44138 9.15052 5.34726 9.13176 5.25948 9.09531C5.17169 9.05885 5.09196 9.00543 5.02486 8.9381L3.58042 7.49366C3.51324 7.42647 3.45995 7.34672 3.42359 7.25894C3.38723 7.17117 3.36852 7.07709 3.36852 6.98208C3.36852 6.88707 3.38723 6.793 3.42359 6.70522C3.45995 6.61744 3.51324 6.53769 3.58042 6.47051C3.6476 6.40333 3.72735 6.35004 3.81513 6.31368C3.90291 6.27732 3.99698 6.25861 4.09199 6.25861C4.187 6.25861 4.28108 6.27732 4.36885 6.31368C4.45663 6.35004 4.53639 6.40333 4.60357 6.47051L5.53704 7.40278L8.39644 4.54278C8.46363 4.47575 8.54338 4.42262 8.63112 4.38643C8.71886 4.35023 8.81287 4.33169 8.90778 4.33186C9.00269 4.33203 9.09664 4.3509 9.18425 4.3874C9.27186 4.4239 9.35142 4.47731 9.41838 4.54458ZM13 6.5C13 7.78558 12.6188 9.04228 11.9046 10.1112C11.1903 11.1801 10.1752 12.0132 8.98744 12.5052C7.79972 12.9972 6.49279 13.1259 5.23191 12.8751C3.97104 12.6243 2.81285 12.0052 1.90381 11.0962C0.994767 10.1872 0.375702 9.02896 0.124899 7.76809C-0.125905 6.50721 0.00281636 5.20028 0.494786 4.01256C0.986755 2.82484 1.81988 1.80968 2.8888 1.09545C3.95772 0.381218 5.21442 0 6.5 0C8.22332 0.00191162 9.87551 0.687345 11.0941 1.90592C12.3127 3.12449 12.9981 4.77668 13 6.5ZM11.5556 6.5C11.5556 5.5001 11.2591 4.52266 10.7035 3.69128C10.148 2.8599 9.35846 2.21192 8.43468 1.82928C7.5109 1.44663 6.49439 1.34652 5.51371 1.54159C4.53303 1.73665 3.63222 2.21815 2.92518 2.92518C2.21815 3.63221 1.73666 4.53303 1.54159 5.51371C1.34652 6.49439 1.44664 7.51089 1.82928 8.43468C2.21192 9.35846 2.8599 10.148 3.69129 10.7035C4.52267 11.259 5.50011 11.5556 6.5 11.5556C7.84038 11.5541 9.12544 11.021 10.0732 10.0732C11.021 9.12544 11.5541 7.84037 11.5556 6.5Z"
        fill="black"
      />
    </svg>
  )
}
