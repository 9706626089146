'use client'
import { styled } from '../config'

function Svg(props) {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="Group 1000006360">
        <circle
          id="Ellipse 157"
          cx="2"
          cy="2"
          r="2"
          transform="rotate(90 2 2)"
          fill="black"
        />
        <circle
          id="Ellipse 158"
          cx="2"
          cy="8"
          r="2"
          transform="rotate(90 2 8)"
          fill="black"
        />
        <circle
          id="Ellipse 159"
          cx="2"
          cy="14"
          r="2"
          transform="rotate(90 2 14)"
          fill="black"
        />
      </g>
    </svg>
  )
}

export const Hamburger = styled(Svg)
